import SpaceshipGame from './Spaceship';

function App() {
    return (
        <div className='App'>
            <SpaceshipGame />
        </div>
    );
}

export default App;
